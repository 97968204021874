import toUserConverter from '@/services/toUserConverter'

/**
 * @param {object}   state
 * @param {object[]} users
 */
const setAllUsers = (state, users) => {
  state.allUsers = users.map(user => toUserConverter.from.user(user))
}

/**
 * @param {object}   state
 * @param {object[]} users
 */
const setUsers = (state, users) => {
  state.users = users.map(user => toUserConverter.from.user(user))
}

/**
 * @param {object} state
 * @param {number} count
 */
const setUsersCount = (state, count) => {
  state.usersCount = count
}

/**
 * @param {object}   state
 * @param {object[]} teams
 */
const setTeams = (state, teams) => {
  state.teams = [...teams].sort((a, b) => a.name.localeCompare(b.name))
}

/**
 * @param {object}   state
 * @param {object[]} vendors
 */
const setVendors = (state, vendors) => {
  state.vendors = [...vendors].sort((a, b) => a.name.localeCompare(b.name))
}

/**
 * @param {object}   state
 * @param {object[]} vendor
 */
const setActiveVendor = (state, vendor) => {
  state.activeVendor = vendor
}

/**
 * @param {object}   state
 * @param {object[]} vendors
 */
const setVendorsByTeam = (state, vendors) => {
  state.vendors = [...vendors].sort((a, b) => a.name.localeCompare(b.name))
}

/**
 * @param {object}   state
 * @param {object[]} registrations
 */
const setRegistrations = (state, registrations) => {
  state.registrations = registrations
}

/**
 * @param {object}   state
 * @param {object[]} users
 */
const setMentionableUsers = (state, users) => {
  state.mentionableUsers = users
}

/**
 * @param {object}   state
 * @param {object[]} users
 */
const setUsersByTeam = (state, users) => {
  state.usersByTeam = users.map(user => toUserConverter.from.user(user))
}

/**
 * @param {object}   state
 * @param {object[]} users
 */
const setUsersByVendor = (state, users) => {
  state.usersByVendor = users.map(user => toUserConverter.from.user(user))
}

/**
 * @param {object} state
 * @param {number} count
 */
const setUsersByTeamCount = (state, count) => {
  state.usersByTeamCount = count
}

/**
 * @param {object} state
 * @param {number} count
 */
const setUsersByVendorCount = (state, count) => {
  state.usersByVendorCount = count
}

/**
 * @param {object} state
 * @param {number} count
 */
const setRegistrationsCount = (state, count) => {
  state.registrationsCount = count
}

/**
 * @param {object} state
 * @param {object} user
 */
const addUser = (state, user) => {
  state.users.push(user)
  state.usersCount += 1
}

/**
 * @param {object} state
 * @param {object} user
 */
const updateUser = (state, user) => {
  const index = state.users.findIndex(singleUser => singleUser.id === user.id)

  if (index !== -1) {
    const newUser = {
      ...state.users[index],
      ...user
    }

    state.users.splice(index, 1, newUser)
  }
}

/**
 * @param {object} state
 * @param {object} user
 */
const updateUserByTeam = (state, user) => {
  const index = state.usersByTeam.findIndex(
    singleUser => singleUser.id === user.id
  )

  if (index !== -1) {
    const newUser = {
      ...state.usersByTeam[index],
      ...user
    }

    state.usersByTeam.splice(index, 1, newUser)
  }
}

/**
 * @param {object} state
 * @param {string} vendorEmail
 */
const deleteVendorRegistration = (state, vendorEmail) => {
  const index = state.registrations.findIndex(
    vendor => vendor.email === vendorEmail
  )

  state.registrations.splice(index, 1)
  state.registrationsCount -= 1
}

/**
 * @param {object} state
 * @param {number} userId
 */
const deleteUserFromTeam = (state, userId) => {
  const index = state.usersByTeam.findIndex(user => user.id === userId)

  state.usersByTeam.splice(index, 1)
  state.usersByTeamCount -= 1
}

/**
 * @param {object} state
 * @param {number} userId
 */
const deleteUserFromVendor = (state, userId) => {
  const index = state.usersByVendor.findIndex(user => user.id === userId)

  state.usersByVendor.splice(index, 1)
  state.usersByVendorCount -= 1
}

/**
 * @param {object} state
 * @param {string} userId
 */
const deleteUser = (state, userId) => {
  const index = state.users.findIndex(user => user.id === userId)

  state.users.splice(index, 1)
  state.usersCount -= 1
}

const mutations = {
  setUsers,
  setUsersCount,
  addUser,
  setAllUsers,
  updateUser,
  deleteUser,
  setTeams,
  setVendors,
  setActiveVendor,
  setVendorsByTeam,
  setRegistrations,
  setUsersByTeam,
  setUsersByVendor,
  setMentionableUsers,
  setUsersByTeamCount,
  setUsersByVendorCount,
  setRegistrationsCount,
  deleteUserFromTeam,
  deleteUserFromVendor,
  updateUserByTeam,
  deleteVendorRegistration
}

export default mutations
